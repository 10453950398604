<template>
  <div fluid class="upload-hover">
    <v-row no-gutters>
      <v-col></v-col>
      <v-col cols="6">
        <v-card-title>
          <h1 v-if="this.$route.name ==='customer'"> Orders </h1>
          <h1 v-if="this.$route.name ==='customerDraft'">Orders {{ this.$route.params.id }} <span class="orange--text">(draft)</span></h1>
          <v-spacer></v-spacer>
          <v-btn
            icon
            tile
            outlined
            text
            large
            class="mt-n2 ml-n4 rounded-lg"
            @click="$router.push('/dashboard/orders/draft')"
          >
            <v-icon large
              >mdi-close</v-icon
            >
          </v-btn>
        </v-card-title>
      </v-col>
      <v-col></v-col>
    </v-row>

    <v-row>
      <v-col cols="3" class="">
        <v-list-item
          class="mx-3"
          @click="swapComponent('OrderRequirements')"
          link
          :class="{ active: currentComponent === 'OrderRequirements' }"
        >
          <v-list-item-icon>
            <v-icon
              large
              :color="currentComponent !== 'OrderRequirements' ? 'green' : ''"
              :class="{ 'red--text': getOrderRequirementError}"
            >
              {{
                currentComponent !== "OrderRequirements"
                  ? "mdi-check-circle-outline"
                  : "mdi-close-circle-outline"
              }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content class="ml-n5" :class="{ 'red--text': getOrderRequirementError}">
            <v-list-item-title class="text-subtitle-1">
              Order Requirements
            </v-list-item-title>
            <v-list-item-subtitle class="text-body2 text-muted" :class="{ 'red--text': getOrderRequirementError}">
              Fill in your order requirements.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="mx-3"
          @click="swapComponent('OrderDetails')"
          :class="{ active: currentComponent === 'OrderDetails' }"
        >
          <v-list-item-icon>
            <v-icon
              large
              :color="currentComponent !== 'OrderDetails' ? 'green' : ''"
            >
              {{
                currentComponent !== "OrderDetails"
                  ? "mdi-check-circle-outline"
                  : "mdi-close-circle-outline"
              }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content class="ml-n5">
            <v-list-item-title class="text-subtitle-1">
              Order details</v-list-item-title
            >
            <v-list-item-subtitle class="text-body2 text-muted">
              Add your order details
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="mx-3"
          @click="swapComponent('FilesUpload')"
          :class="{ active: currentComponent === 'FilesUpload' }"
        >
          <v-list-item-icon>
            <v-icon
              large
              :color="currentComponent !== 'FilesUpload' ? 'green' : ''"
            >
              {{
                currentComponent !== "FilesUpload"
                  ? "mdi-check-circle-outline"
                  : "mdi-close-circle-outline"
              }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content class="ml-n5">
            <v-list-item-title class="text-subtitle-1">
              Files and instructions</v-list-item-title
            >
            <v-list-item-subtitle class="text-body2 text-muted">
              Upload files and add instructions for the writer.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="6">
        <keep-alive>
          <div :is="currentComponent" :swap-component="swapComponent"></div>
        </keep-alive>
      </v-col>

      <v-col cols="3"> </v-col>
    </v-row>
  </div>
</template>

<script>
import colorMxn from "@/mixins/colorMxn";
import validationMxn from '@/mixins/validation_mixin';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: "Orders",
  components: {
    FilesUpload: () => import("@/modules/Orders/components/FilesUpload"),
    OrderDetails: () => import("@/modules/Orders/components/OrderDetails"),
    OrderRequirements: () =>
      import("@/modules/Orders/components/OrderRequirements")
  },
  mixins: [colorMxn, validationMxn],
  data() {
    return {
      currentComponent: "OrderRequirements",
      actionClass: "",
      icon: 'mdi-check-circle-outline"',
      order_id: this.$route.params.id  !== undefined ? this.$route.params.id: '',
    };
  },
  computed: {
    ...mapGetters(['getOrderRequirementError', 'getCurrentComponent', 'getUploadedFile', 'getOrderDetail']),
  },
  watch: {
    getCurrentComponent(val) {
      this.currentComponent = val;
    },
  }, 
  mounted() {
    if (this.$route.name === 'customerDraft') {
      this.getOrders();
    }
  },
  methods: {
    ...mapMutations(['setCurrentComponent','setUploadedFile', 'setOrderDetail', 'setMountUploadedFile', 'setAmount']),
    swapComponent(component) {
      switch (this.currentComponent) {
        case 'OrderRequirements':
          if (this.getOrderRequirementError) {
            // this.currentComponent = this.currentComponent;
            return;
          } else {
            this.currentComponent = component;
            this.setCurrentComponent(component);
          }
          break;
        default:
          this.currentComponent = component;
          this.setCurrentComponent(component);
          break;
      }
    },
    async getOrders() {
      const payload = {
        page: 1,
      };
      const fullPayload = {
        params: payload,
        endpoint: `/order-detail/${this.$route.params.id}`
      };

      const response = await this.performGetActions(fullPayload);
      this.setMountUploadedFile(response.data[0].file);
      this.setOrderDetail(response.data[0]);
      this.setAmount = response.data[0].amount;
    },
  }
};
</script>

<style >
@import url("../../assets/styles/order.css");
.form-div {
  height: 450px;
  overflow-y: scroll;
}

.form-div::-webkit-scrollbar {
  display: none;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}

</style>
